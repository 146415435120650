import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      list_usersTypes: [],
      list_userGroup: [],
      isBalai: false,
      isSatker: false,
      listGiat: [],
      listSatker: [],
      listEvent: [],
      listRegister: [],

      tb_etc: [],
      headers: [],
      tbTotal: [],
      tbTotalPusat: [],
      listRegisterSet: [],
      giatHover: [],
      uploadRule: [(v) => !!v || "Tidak Boleh Kosong"],
      buttonKey: 0,
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.$nextTick(() => {
      this.G_GetRef("register").then((data) => {
        this.listRegister = data;
      });

      this.getTable();

      // this.G_GetAny('sakti/event').then(res => {
      //   let resData = res.data.content

      //   let listData = [];
      //   let arrSub = {};
      //   let setDefault = null

      //   resData.forEach(v => {

      //     if (v.asDefault) {
      //       setDefault = v.saktiEventId;
      //     }

      //     arrSub = {};

      //     arrSub['text'] = v.saktiEventNm;
      //     arrSub['value'] = v.saktiEventId;

      //     listData.push(arrSub);
      //   });

      //   this.listEvent = listData;

      //   // this.listEvent.unshift({
      //   //   'text': 'Semua Event',
      //   //   'value': 'all'
      //   // })
      //   // this.$refs.event.setValue(setDefault)

      // })
    });
  },
  components: {
    myTable,
  },
  methods: {
    downloadTemplateDirektorat(registerId) {
      console.log("open download");

      this.G_PostAnyCustom(
        "/pagu/arahan/download/direktorat/template?sakti_event_id=" +
          this.$route.query.eventId +
          "&register_id=" +
          registerId
      )
        .then((res) => {
          console.log(res);
          if (!res.status) {
            window.open(
              process.env.VUE_APP_URL_API + res.data.fileUrl,
              "_blank"
            );
          } else {
            this.$snotify.warning(res.data.message);
          }
        })
        .finally(() => {});
    },
    getTable() {
      let event = this.$route.query.eventId;
      // let register = null;
      // if (this.$route.query.eventId) {
      //   event = this.$route.query.eventId;
      // }

      $("#listKegiatan").html(``);

      ///////// per balai

      this.G_GetAny(
        "pagu-arahan-direktorat/" + this.$route.query.eventId + "/list-register"
      ).then((res) => {
        this.listRegisterSet = res.data.content;

        this.$nextTick(() => {
          let tmpListReg = res.data.content;

          if (tmpListReg.length) {
            this.getAllRef(tmpListReg);
          }
        });
      });
    },

    edit(kdsatker, kdgiat) {
      this.G_GetAny(`direktorat-giat/${kdsatker}/${kdgiat}`).then((res) => {
        this.getData = res.data;
        // this.setId = res.data.userId

        this.$refs.modal.open("Edit Data Kegiatan per Direktorat", 600);

        this.$nextTick(() => {});
      });
    },
    upload() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.setId = null;
      this.getData = [];
      this.$refs.modal.open("Upload Data Pagu Arahan Direktorat", 600);
    },
    store() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.append("kdsatker", this.$refs.kdsatker.getValue());
      formData.append("kdgiat", this.$refs.kdgiat.getValue());

      if (this.setId) {
        formData.append("id", this.setId);
      }

      if (this.$refs.form.validate()) {
        this.$refs.modal.loading();

        if (this.setId) {
          this.G_PutAny("direktorat-giat", formData)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Mengubah Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning(res.data.message, "Terjadi Kesalahan");
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        } else {
          this.G_PostAny("direktorat-giat", formData)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Menyimpan Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning(res.data.message);
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        }
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    uploadPaguDirektorat() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.set("sakti_event_id", this.$route.query.eventId);

      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);

        this.G_PostAnyCustom("/pagu/arahan/upload/direktorat", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.files = [];
              this.$refs.modal.close();
              this.$snotify.success("Berhasil Upload Data");
            } else {
              this.$snotify.warning(res.data.message);
            }
          })
          .finally(() => {
            this.$refs.btnAct.load(false);
          });
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    async hapus(kdsatker, kdgiat) {
      let judul = "Hapus Data";
      let subJudul = "Yakin Akan Hapus Data Ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();
        this.G_DeleteAny(`direktorat-giat/${kdsatker}/${kdgiat}`)
          .then((res) => {
            if (res) {
              this.$refs.confirm.close();
              this.$refs.modal.close();
              this.getTable();
              this.$snotify.success(res.message);
              // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
            } else {
              this.$snotify.warning(res.message);

              // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    async getAllRef(tmpListReg) {
      await tmpListReg.forEach((vTmp) => {
        this.G_GetAny(
          `pagu-arahan-direktorat/${this.$route.query.eventId}/${vTmp.registerId}`
        ).then((resTB) => {
          console.log(resTB);
          let lr = resTB.data;
          let extraHead = resTB.data.kdgiats;
          let content = resTB.data.content;
          let footTotal = resTB.data.paguTotal;
          let ArahanPusatTotal = resTB.data.paguPusatTotal;
          let giatName = resTB.data.nmpembinas;
          let selisih = resTB.data.selisih;

          let tmpHead = ``;
          let tmpGiatName = ``;
          let tmpBody = ``;
          let tmpBodyExtra = ``;
          let tmpFootTotal = ``;
          let tmpFootArahanPusat = ``;
          let tmpFootArahanPusatTotal = ``;
          let tmpFootSelisih = ``;

          for (const [key, v] of Object.entries(giatName)) {
            tmpGiatName += `<th class="text-right">${v}</th>`;
          }

          extraHead.forEach((eh) => {
            tmpHead += `<th class="text-right text-uppercase">${eh}</th>`;
          });

          for (const [key, v] of Object.entries(footTotal)) {
            if (v > ArahanPusatTotal[key]) {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;background:#C62828;">${this.G_numFormat(
                v
              )}</td>`;
            } else if (v < ArahanPusatTotal[key]) {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;background:#FFEE58;color: #000;">${this.G_numFormat(
                v
              )}</td>`;
            } else {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;">${this.G_numFormat(
                v
              )}</td>`;
            }
          }

          for (const [key, v] of Object.entries(ArahanPusatTotal)) {
            tmpFootArahanPusatTotal += `<td class="text-right">${this.G_numFormat(
              v
            )}</td>`;
          }

          for (const [key, v] of Object.entries(selisih)) {
            tmpFootSelisih += `<td class="text-right">${this.G_numFormat(
              v
            )}</td>`;
          }

          let setFootTotal = `
              <tr style="background: #585858;color: #fff;font-weight: 500;">
                <td colspan="3" style="font-size:12px;">Total Pagu</td>
                <td class="text-right" style="font-size:12px;">${this.G_numFormat(
                  resTB.data.grandPaguTotal
                )}</td>
                ${tmpFootTotal}
              </tr>
          `;

          let setFootArahanPusatTotal = `
            <tr style="color: #151515;font-weight: 600;">
                <td colspan="3" style="font-size:12px;">Total Pagu Pusat</td>
                <td class="text-right" style="font-size:12px;">${this.G_numFormat(
                  resTB.data.grandPaguPusatTotal
                )}</td>

                ${tmpFootArahanPusatTotal}
              </tr>
          `;

          let setFootSelisih = `
          <tr style="color: #151515;font-weight: 600;">
              <td colspan="3" style="font-size:12px;">Selisih</td>
              <td class="text-right" style="font-size:12px;">${this.G_numFormat(
                resTB.data.grandSelisih
              )}</td>

              ${tmpFootSelisih}
            </tr>
        `;

          content.forEach((c, k) => {
            tmpBodyExtra = ``;
            let pagu = c.pagu;
            for (const [key, v] of Object.entries(pagu)) {
              tmpBodyExtra += `<td class="text-right">${this.G_numFormat(
                v
              )}</td>`;
            }

            tmpBody += `
            <tr>
              <td>${k + 1}</td>
              <td>${c.kdsatker}</td>
              <td>${c.nmsatker}</td>
              <td class="text-right">${this.G_numFormat(c.total)}</td>
              ${tmpBodyExtra}
            </tr>`;
          });

          $("#listKegiatan").append(`
            <hr class="mt-10 mb-10" style="border: 1px dashed #d5d5d5;">
            <div class="d-flex justify-content-between mb-4">
              <h6 class="mb-4 mt-0" style="border-left:5px solid #00796b;padding-left:20px;">${
                lr.registerNm
              }</h6>
              <button type="button" class="btn btn-success" onclick="downloadTemplateDirektorat('${localStorage.getItem(
                "token"
              )}', ${this.$route.query.eventId}, '${lr.registerId}')" :key="${
            this.buttonKey
          }">Download Template <i class="la la-download ml-2"></i></button>
            </div>
            <div class="table-responsive-lg tableFixHead scrollStyle scrollStyle-v" style="cursor: grab;max-height: 70vh !important;">
                <table class="table paket zn-table">
                    <thead>
                        <tr style="background: #00796b;color: #fff;">
                            <th rowspan="3" class="text-center text-uppercase">No</th>
                            <th rowspan="3" class="text-left text-uppercase">Kode Satker</th>
                            <th rowspan="3" class="text-left text-uppercase">Nama Satker</th>
                            <th rowspan="3" class="text-right text-uppercase">Total</th>
                            <th colspan="${
                              extraHead.length
                            }" class="text-center text-uppercase">Kegiatan</th>
                        </tr>
                        <tr style="background: #00796b;color: #fff;top: 42px;">
                          ${tmpHead}
                        </tr>
                        <tr style="background: #00796b;color: #fff;top: 84px;">
                        ${tmpGiatName}
                        </tr>
                    </thead>
                    <tbody>
                      ${tmpBody}
                      ${setFootTotal}
                      ${setFootArahanPusatTotal}
                      ${setFootSelisih}
                        
                    </tbody>
                </table>
            </div>
            
            `);

          this.buttonKey++;
        });
      });
    },
  },
};
