<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
        :items-per-page="20"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

            <template v-slot:item.noRow="{ item }">
                <div v-if="item.kdsatker">
                    {{tbData.map(function(x) {return x.kdsatker; }).indexOf(item.kdsatker)+1}}

                </div>
            </template>
            <template v-slot:item.total="{item}">
                <div v-if="item.total > 0" class="p-2 text-right">
                    {{G_numFormat(item.total)}}
                </div>
            </template>

            <template v-for="(slot,i) in tbData['0'].pagu" v-slot:[`item.pagu[${i}]`]="{ item }">

                  <v-tooltip bottom :key="i">
                    <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >{{G_numFormat(item.pagu[i])}}</span>
                    </template>
                    <span>Giat : {{giatHover[i]}}</span>
                    </v-tooltip>

             
            </template>

            <template slot="body.append">
                <tr style="background: #585858;color: #fff;font-weight: 500;">
                    <td colspan="4" style="font-size:12px;">Total</td>
                    <template v-for="(slot,i) in tbTotal">
                        <td :key="i" v-if="slot > tbTotalPusat[i]" class="text-right" style="font-size:12px;background:#C62828;">{{ G_numFormat(slot) }}</td>
                        <td :key="i" v-else-if="slot < tbTotalPusat[i]" class="text-right" style="font-size: 12px;background: #FFEE58;color: #000;">{{ G_numFormat(slot) }}</td>
                        <td :key="i" v-else class="text-right" style="font-size:12px;">{{ G_numFormat(slot) }}</td>

                    </template>

                </tr>
            </template>

        </v-data-table>



    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                pagination: {
                    rowsPerPage: 20
                },

            }
        },
        mounted() {

        },
        methods: {
            sumField(key) {
                // sum data in give key (property)
                return this.tbData.reduce((a, b) => a + (b[key] || 0), 0)
            },

            getTable() {
                this.$emit('getTable');
            },
            edit(id) {
                this.$emit('edit', id);
            },
            hapus(satkerId, kdgiat) {
                this.$emit('hapus', satkerId, kdgiat);
            },


        },

        props: ['tbData', 'headers', 'search', 'loadingTb', 'tbTotal','tbTotalPusat', 'tb_etc','giatHover'],
    }
</script>