var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"items-per-page":20,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.noRow",fn:function(ref){
var item = ref.item;
return [(item.kdsatker)?_c('div',[_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.kdsatker; }).indexOf(item.kdsatker)+1)+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.total > 0)?_c('div',{staticClass:"p-2 text-right"},[_vm._v(" "+_vm._s(_vm.G_numFormat(item.total))+" ")]):_vm._e()]}},_vm._l((_vm.tbData['0'].pagu),function(slot,i){return {key:("item.pagu[" + i + "]"),fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.G_numFormat(item.pagu[i])))])]}}],null,true)},[_c('span',[_vm._v("Giat : "+_vm._s(_vm.giatHover[i]))])])]}}})],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"background":"#585858","color":"#fff","font-weight":"500"}},[_c('td',{staticStyle:{"font-size":"12px"},attrs:{"colspan":"4"}},[_vm._v("Total")]),_vm._l((_vm.tbTotal),function(slot,i){return [(slot > _vm.tbTotalPusat[i])?_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px","background":"#C62828"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))]):(slot < _vm.tbTotalPusat[i])?_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px","background":"#FFEE58","color":"#000"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))]):_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))])]})],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }